import React, { useState } from 'react';
import 'react-dates/initialize';
import { DateRangePicker as RdDateRangePicker } from 'react-dates';
import 'react-dates/lib/css/_datepicker.css';
import { Icon } from 'semantic-ui-react';

function DateRangePicker({
  fullSize,
  onClose,
  label,
  calendarInfo,
  startDatePlaceholderText,
  endDatePlaceholderText,
  onClear,
  opened = true,
  reopenPickerOnClearDates = true
}) {
  const [calendarStartDate, setCalendarStartDate] = useState();
  const [calendarEndDate, setCalendarEndDate] = useState();
  const [focusedInput, setFocusedInput] = useState(fullSize && opened ? 'startDate' : null);

  return (
    <>
      {label && <label className="time-picker-label">{label}</label>}
      <RdDateRangePicker
        startDate={calendarStartDate}
        startDateId="start-date"
        endDate={calendarEndDate}
        endDateId="end-date"
        displayFormat="YYYY-MM-DD"
        focusedInput={focusedInput}
        onFocusChange={fi => setFocusedInput(fi)}
        hideKeyboardShortcutsPanel
        startDatePlaceholderText={startDatePlaceholderText}
        endDatePlaceholderText={endDatePlaceholderText}
        customArrowIcon={<Icon name="long arrow alternate right" />}
        showClearDates
        showDefaultInputIcon
        reopenPickerOnClearDates={reopenPickerOnClearDates}
        orientation={fullSize ? 'horizontal' : 'vertical'}
        withPortal={!fullSize}
        withFullScreenPortal={!fullSize}
        daySize={fullSize ? 60 : 40}
        onDatesChange={({ startDate, endDate }) => {
          setCalendarStartDate(startDate);
          setCalendarEndDate(endDate);

          if (!startDate && !endDate && onClear) {
            onClear();
          }
        }}
        onClose={onClose}
        renderCalendarInfo={() =>
          calendarInfo &&
          fullSize && <p style={{ background: '#e4e7e7', padding: '20px', fontWeight: 'bold' }}>{calendarInfo}</p>
        }
        calendarInfoPosition="top"
      />
    </>
  );
}

export default React.memo(DateRangePicker);
