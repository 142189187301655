import { getRequest, postRequest } from '../../utils/fetch';

export const fetchTransactionByReferenceId = referenceId => getRequest(`/Transaction/ClientReferenceId/${referenceId}`);

export const cancelInvoice = id => postRequest(`/Transaction/CancelInvoice/${id}`);

export const cancelSwishPayment = id => postRequest(`/Transaction/CancelSwishPayment/${id}`);

export const resendInvoice = id => postRequest(`/Transaction/ResendInvoice/${id}`);

export const getTransactionById = (id, refetchPaymentUrl) =>
  getRequest(`/Transaction/GetById?id=${id}&refetchPaymentUrl=${refetchPaymentUrl}`);

export const getTransactions = (golfClubId, dateFrom, dateTo) =>
  getRequest('/Transaction/GetTransactions', {
    golfClubId,
    withinDateStart: dateFrom,
    withinDateEnd: dateTo
  });

/**
 * Gets all payouts created within a specified period for the golf club.
 * @param {*} golfClubId Golf club id.
 * @param {*} dateFrom Date from which to fetch data. Can't be less that 90 days before.
 * @param {*} dateTo Dat to which to fetch data.
 * @returns List of payouts for the club.
 */
export const getPayouts = (golfClubId, dateFrom, dateTo) =>
  getRequest('/Transaction/GetPayouts', {
    golfClubId,
    withinDateStart: dateFrom,
    withinDateEnd: dateTo
  });

export const getCustomerPeriodPayoutReport = (golfClubId, dateFrom, dateTo) => {
  const response = postRequest('/Transaction/GetCustomerPeriodPayoutReport', {
    golfClubId,
    withinDateStart: dateFrom,
    withinDateEnd: dateTo
  });

  return response;
};

export const getSwishPaymentStatus = location =>
  getRequest(`/Transaction/checkSwishPaymentStatus?location=${encodeURIComponent(location)}`);
