import React from 'react';
import { Grid, Segment, Table } from 'semantic-ui-react';
import { Translate } from 'react-localize-redux';

import { isValidDate, startOfMonth, endOfMonth, todaysDate, getFormattedPrice } from '../../../utils';
import { ReportDateComponent } from '../ReportDateComponent';
import { CSVLink } from 'react-csv';
import { IAddonReportItem, getAccommodationPackages } from '../../../core/services/product.service';

interface PackagesReportTabProps {
  active: boolean;
  translate: any;
}

export default class PackagesReportTab extends React.Component<PackagesReportTabProps> {
  state = {
    loading: false,
    packages: [] as IAddonReportItem[],
    startDate: startOfMonth(todaysDate()),
    startDateValid: true,
    endDate: endOfMonth(todaysDate()),
    endDateValid: true
  };

  packageHeaders = [
    { label: this.props.translate('product-addons.headers.name'), key: 'productName' },
    { label: this.props.translate('default.accommodation'), key: 'accommodationName' },
    { label: this.props.translate('product-addons.headers.price'), key: 'chargeAmount' },
    { label: '', key: 'chargeCurrency' },
    { label: this.props.translate('club-admin.report-tabs.order-id'), key: 'accommodationBookingId' },
    { label: this.props.translate('default.checkin'), key: 'checkinDate' },
    { label: this.props.translate('default.checkout'), key: 'checkoutDate' },
    { label: this.props.translate('product-addons.addons'), key: 'productAddons' }
  ];

  validateDatetimeChange = (_e: any, { name, value }: any) => {
    let { startDate, endDate, startDateValid, endDateValid } = this.state;

    if (name === 'startDate') {
      startDate = value;
      startDateValid = isValidDate(startDate);
      if (startDateValid) {
        endDate = endOfMonth(startDate);
      }
    } else {
      endDate = value;
    }
    endDateValid = isValidDate(endDate) && endDate >= startDate;
    this.setState({ startDate, endDate, startDateValid, endDateValid });
  };

  fetchPackages = async () => {
    const { startDate, endDate, startDateValid, endDateValid } = this.state;
    if (startDateValid && endDateValid) {
      this.setState({ loading: true });

      try {
        const packages = await getAccommodationPackages(startDate, endDate);

        if ('data' in packages) {
          this.setState({
            loading: false,
            packages: packages.data
          });
        } else {
          this.setState({
            loading: false
          });
        }
      } catch (error) {
        this.setState({ loading: false });
      }
    }
  };

  processDataForExport = () => {
    return this.state.packages.map(pkg => {
      return {
        ...pkg,
        productAddons: pkg.productAddons.map(
          addon => `${addon.count}x ${this.props.translate(`product-addons.type-names.${addon.name}`)}`
        )
      };
    });
  };

  render() {
    const { startDate, endDate, startDateValid, endDateValid, loading, packages } = this.state;

    const exportData = this.processDataForExport;

    const packagesCsvReport = {
      data: exportData(),
      headers: this.packageHeaders,
      filename: `${this.props.translate('club-admin.packages')}.csv`,
      separator: ';'
    };

    if (this.props.active) {
      return (
        <Translate>
          {({ translate }) => (
            <Segment>
              <div className="p-1">
                <ReportDateComponent
                  startDate={startDate}
                  startDateValid={startDateValid}
                  endDate={endDate}
                  endDateValid={endDateValid}
                  loading={loading}
                  fetchBookings={this.fetchPackages}
                  validateDatetimeChange={this.validateDatetimeChange}
                />

                <Grid>
                  <Grid.Column floated="left" width="5">
                    <Translate id="club-admin.packages" />
                  </Grid.Column>
                  {packages?.length > 0 && (
                    <Grid.Column floated="right" width="5" textAlign="right">
                      <CSVLink {...packagesCsvReport}>{translate('club-admin.export-to-csv')}</CSVLink>
                    </Grid.Column>
                  )}
                </Grid>

                <Segment loading={loading}>
                  <Table>
                    <Table.Header>
                      <Table.Row>
                        <Table.HeaderCell>
                          <Translate id="product-addons.headers.name" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>#</Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.accommodation" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="club-admin.report-tabs.order-id" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.checkin" />
                        </Table.HeaderCell>
                        <Table.HeaderCell>
                          <Translate id="default.checkout" />
                        </Table.HeaderCell>
                        <Table.HeaderCell textAlign="center">
                          <Translate id="product-addons.headers.price" />
                        </Table.HeaderCell>
                      </Table.Row>
                    </Table.Header>
                    <Table.Body>
                      {packages?.map((pkg, i) => {
                        return (
                          <>
                            <Table.Row key={i}>
                              <Table.Cell>{pkg.productName}</Table.Cell>
                              <Table.Cell>{pkg.itemsCount}</Table.Cell>
                              <Table.Cell>{pkg.accommodationName}</Table.Cell>
                              <Table.Cell>{pkg.accommodationBookingId}</Table.Cell>
                              <Table.Cell>{pkg.checkinDate}</Table.Cell>
                              <Table.Cell>{pkg.checkoutDate}</Table.Cell>
                              <Table.Cell textAlign="right">
                                {getFormattedPrice(pkg.chargeAmount, pkg.chargeCurrency)}
                              </Table.Cell>
                            </Table.Row>
                            {pkg.productAddons?.map((addon, j) => (
                              <Table.Row key={`${i}_${j}_${addon.name}`}>
                                <Table.Cell
                                  style={{
                                    paddingLeft: '3em',
                                    fontSize: 'smaller',
                                    paddingTop: '2px',
                                    paddingBottom: '2px'
                                  }}
                                >
                                  {`${addon.count}x ${translate(`product-addons.type-names.${addon.name}`)}`}
                                </Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                                <Table.Cell style={{ paddingTop: '2px', paddingBottom: '2px' }}>&nbsp;</Table.Cell>
                              </Table.Row>
                            ))}
                          </>
                        );
                      })}
                      {packages?.length > 0 && (
                        <Table.Row style={{ fontWeight: 700 }}>
                          <Table.Cell>{translate('club-admin.sum')}</Table.Cell>
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell />
                          <Table.Cell textAlign="right">
                            {getFormattedPrice(
                              packages.reduce((acc, curr) => acc + curr.chargeAmount, 0),
                              packages[0].chargeCurrency
                            )}
                          </Table.Cell>
                        </Table.Row>
                      )}
                    </Table.Body>
                  </Table>
                </Segment>
              </div>
            </Segment>
          )}
        </Translate>
      );
    }

    return <></>;
  }
}
