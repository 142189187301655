import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Translate, withLocalize } from 'react-localize-redux';
import { Button, Loader, Table } from 'semantic-ui-react';
import { ActiveRequest, TransactionStatus, TransactionType } from '../../core/enums';
import { getTransactionById } from '../../core/services';

function Transactions({ order, golfClubCurrency, onResend, onCancel, activeRequest, setActiveRequest, translate }) {
  const [showNote, setShowNote] = useState(false);
  const [showAction, setShowAction] = useState(false);
  const [paymentLink, setPaymentLink] = useState('');
  const [transactions, setTransactions] = useState([]);

  useEffect(() => {
    const orderTransactions = order.transactions.sort((a, b) => a.id - b.id);
    const hasConnectedRefund = orderTransactions.some(x => x.parentTransactionId);
    const hasInvoice = orderTransactions.some(
      x => x.type === TransactionType.StripeEmailPayment || x.type === TransactionType.SwishPayment
    );

    setTransactions(orderTransactions);
    setShowNote(hasConnectedRefund);
    setShowAction(hasInvoice);
  }, [order]);

  const onGetPaymentUrl = async transaction => {
    if (transaction.paymentUrl) {
      setPaymentLink(transaction.paymentUrl);
    } else {
      setActiveRequest(ActiveRequest.FETCHING_TRANSACTION);
      const { data } = await getTransactionById(transaction.id, true);
      if (data) {
        setPaymentLink(data.paymentUrl);
      }
      setActiveRequest(null);
    }
  };

  return (
    <>
      <Table striped style={{ maxWidth: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="default.id" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.date" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.type" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.amount" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.status" />
            </Table.HeaderCell>
            {showNote && (
              <Table.HeaderCell>
                <Translate id="default.note" />
              </Table.HeaderCell>
            )}
            {showAction && (
              <Table.HeaderCell />
              // <Translate id="default.action" />
              // </Table.HeaderCell>
            )}
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {transactions.map(transaction => (
            <Table.Row key={transaction.id}>
              <Table.Cell>{transaction.id}</Table.Cell>
              <Table.Cell>{moment(transaction.dateCreated).format('YYYY-MM-DD HH:mm')}</Table.Cell>
              <Table.Cell>{translate(`transactions.type-${transaction.type}`)}</Table.Cell>
              <Table.Cell>{`${transaction.amount} ${golfClubCurrency}`}</Table.Cell>
              <Table.Cell>{translate(`transactions.status-${transaction.status}`)}</Table.Cell>
              {showNote && (
                <Table.Cell>
                  {transaction.parentTransactionId && (
                    <>
                      <Translate id="default.refund-for" /> {transaction.parentTransactionId}
                    </>
                  )}
                </Table.Cell>
              )}
              {showAction && (
                <Table.Cell>
                  {transaction.type === TransactionType.StripeEmailPayment &&
                    transaction.status === TransactionStatus.NOT_COMPLETED && (
                      <>
                        <Button color="blue" disabled={activeRequest} onClick={() => onResend(transaction.id)}>
                          {activeRequest && <Loader size="tiny" active inline />}
                          <Translate id="default.resend" />
                        </Button>
                        <Button color="blue" disabled={activeRequest} onClick={() => onGetPaymentUrl(transaction)}>
                          {activeRequest && <Loader size="tiny" active inline />}
                          <Translate id="default.get-payment-link" />
                        </Button>
                        <Button color="orange" onClick={() => onCancel(transaction.id, transaction.type)}>
                          {activeRequest && <Loader size="tiny" active inline />}
                          <Translate id="default.cancel" />
                        </Button>
                      </>
                    )}
                  {transaction.type === TransactionType.SwishPayment &&
                    transaction.status === TransactionStatus.NOT_COMPLETED && (
                      <Button color="orange" onClick={() => onCancel(transaction.id, transaction.type)}>
                        {activeRequest && <Loader size="tiny" active inline />}
                        <Translate id="default.cancel" />
                      </Button>
                    )}
                </Table.Cell>
              )}
            </Table.Row>
          ))}
        </Table.Body>
      </Table>

      {paymentLink && (
        <p style={{ overflowWrap: 'break-word' }}>
          <Translate id="default.payment-link" />: {'  '}
          <a target="_blank" rel="noopener noreferrer" href={paymentLink}>
            {paymentLink}
          </a>
        </p>
      )}
    </>
  );
}

export default withLocalize(Transactions);
