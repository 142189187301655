import React from 'react';
import { Translate } from 'react-localize-redux';
import { Form, List, Table, Popup, Icon, Modal, Button } from 'semantic-ui-react';
import moment from 'moment';
import { pick } from 'lodash-es';
import { isSuperAdmin } from '../utils';
import { ErrorMessage } from './ErrorMessage';
import { uploadLogotype } from '../core/services/golfclub.service';

// import 'moment/locale/sv';
// moment.locale('sv');
moment.updateLocale('en', {
  week: {
    dow: 1 // Monday is the first day of the week.
  }
});

const GolfClubViewItem = ({ data }) => {
  return (
    <>
      <Table striped style={{ maxWidth: '100%' }}>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>
              <Translate id="default.golfclub" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="default.info" />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="golfclub.lock-period-weekdays" />
              <Popup
                trigger={<Icon name="info circle" />}
                content={
                  <p>
                    <Translate id="golfclub.lock-period-weekdays-info" />
                  </p>
                }
              />
            </Table.HeaderCell>
            <Table.HeaderCell>
              <Translate id="golfclub.lock-period-weekends" />
              <Popup
                trigger={<Icon name="info circle" />}
                content={
                  <p>
                    <Translate id="golfclub.lock-period-weekends-info" />
                  </p>
                }
              />
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          <Table.Row>
            <Table.Cell>{data.name}</Table.Cell>
            <Table.Cell>
              <Popup
                trigger={<Icon name="info circle" />}
                content={<p dangerouslySetInnerHTML={{ __html: data.accommodationInfo }} />}
              />
            </Table.Cell>
            <Table.Cell>{data.golfCartWeekdayHours || 'N/A'}</Table.Cell>
            <Table.Cell>{data.golfCartWeekendHours || 'N/A'}</Table.Cell>
          </Table.Row>
        </Table.Body>
      </Table>
      {/* <List>
        <List.Item>
          <Label size="medium">
            <Translate id="default.golfcourse" />:
          </Label>
          <List size="small" bulleted>
            {data.golfCourses.map(x => {
              return (
                <Segment basic key={x.name}>
                  <Label size="medium">
                    <Translate id="default.name" />:<Label.Detail>{x.name}</Label.Detail>
                  </Label>
                  <Label size="medium">
                    <Translate id="golfclub.nr-holes" />:<Label.Detail>{x.numberOfHoles}</Label.Detail>
                  </Label>
                  <Label size="medium">
                    <Translate id="golfclub.main-course" />:<Label.Detail>{x.isMainCourse ? 'Yes' : 'No'}</Label.Detail>
                  </Label>
                </Segment>
              );
            })}
          </List>
        </List.Item>
      </List> */}
    </>
  );
};

class GolfClubEditItem extends React.Component {
  state = {
    name: '',
    isValidName: true,
    isValidConnectedAccount: true,
    accommodationInfo: '',
    admins: [],
    companies: [],
    golfCourses: [],
    golfCoursesValid: false,
    login: {},
    showUpload: false
  };

  componentDidMount = () => {
    if (!this.props.data) {
      return;
    }
    const { data, login } = this.props;

    this.setState({ ...data, login });

    this.props.dataIsValid(!!data.name);
  };

  validateName = ({ value }) => {
    const isValidName = value && value.trim() !== '';
    this.setState({ name: value, isValidName });
    this.props.dataIsValid(isValidName);
  };

  validateConnectedAccount = (useOnlinePayment, connectedAccountId) => {
    // const isValidConnectedAccount = !useOnlinePayment || (connectedAccountId && connectedAccountId.trim() !== '');
    // we don't need strip's account id for the club anymore
    const isValidConnectedAccount = true;

    this.setState({ useOnlinePayment, connectedAccountId, isValidConnectedAccount });
    this.props.dataIsValid(isValidConnectedAccount);
  };

  onChange = (_e, { name, value }) => {
    this.setState({ [name]: value });
  };

  onChangeReceiveConfirmationEmails = (evt, data) => {
    this.setState({ receiveConfirmationEmails: data.checked });
  };

  onChangeSendCheckinReminderEmail = (evt, data) => {
    this.setState({ sendCheckinReminderEmail: data.checked });

    if (!data.checked) {
      this.setState({ checkinReminderEmailDays: '' });
    }
  };

  onChangeWidgetDisabled = (evt, data) => {
    this.setState({ widgetDisabled: data.checked });
  };

  onChangeOfferPackagesOnly = (evt, data) => {
    this.setState({ widgetOfferPackagesOnly: data.checked });
  };

  onChangeReceiveBookingsReport = (evt, data) => {
    this.setState({ receiveBookingsReport: data.checked });
  };

  subcomponentStateUpdated = (golfCourses, golfCoursesValid) => {
    this.setState({ golfCourses, golfCoursesValid });
  };

  showUploadModal = e => {
    this.setState({ showUpload: true });
    e.preventDefault();
    return false;
  };

  handleImageSelected = event => {
    const file = event.target.files[0];
    const fileReader = new FileReader();
    fileReader.addEventListener('load', () => {
      this.setState({ previewImage: fileReader.result, fileName: file.name, file });
    });
    fileReader.readAsDataURL(file);
  };

  uploadFile = async () => {
    const { file, fileName, id } = this.state;

    const formData = new FormData();
    formData.append('formFile', file);
    formData.append('fileName', fileName);
    formData.append('golfClubId', id);

    this.setState({ saving: true });

    try {
      this.setState({ error: null });

      const logotypeUrl = await uploadLogotype(formData);

      this.setState({
        saving: false,
        showUpload: false,
        logoUrl: logotypeUrl,
        file: null,
        fileName: null,
        previewImage: null
      });
    } catch (error) {
      this.setState({ error: error.message || 'Failed to upload logo' });
    } finally {
      this.setState({ saving: false });
    }
  };

  saveItem = () => {
    const data = pick(this.state, [
      'name',
      'accommodationInfo',
      'accommodationInfoRoomsLodges',
      'golfCourses',
      'admins',
      'companies',
      'golfCartWeekdayHours',
      'golfCartWeekendHours',
      'logoUrl',
      'imageUrl',
      'email',
      'webSite',
      'useOnlinePayment',
      'connectedAccountId',
      'campingCommission',
      'lodgeCommission',
      'golfCartCommission',
      'golfBikeCommission',
      'campingVat',
      'lodgeVat',
      'golfCartVat',
      'golfBikeVat',
      'transactionFee',
      'currency',
      'widgetDisabled',
      'widgetOfferPackagesOnly',
      'receiveBookingsReport',
      'receiveConfirmationEmails',
      'sendCheckinReminderEmail',
      'checkinReminderEmailDays',
      'checkinReminderEmailText'
    ]);

    data.golfCourse = this.props.data.golfCourse;
    const hasOwnProperty = Object.prototype.hasOwnProperty.call(this.props.data, 'id');

    if (hasOwnProperty) {
      data.id = this.props.data.id;
    }
    this.props.saveAction(data, this.props.onSubmit);
  };

  render() {
    const { error, previewImage, saving, showUpload } = this.state;

    return (
      <Translate>
        {({ translate }) => (
          <>
            <Form id={this.props.id} onSubmit={this.saveItem}>
              <List>
                <List.Item>
                  <h3>{translate('golfclub.data')}</h3>
                  <Form.Field>
                    <Form.Input
                      required
                      label={translate('default.golfclub')}
                      type="text"
                      name="name"
                      placeholder={translate('default.golfclub')}
                      onChange={(_e, d) => this.validateName(d)}
                      value={this.state.name}
                      error={!this.state.isValidName}
                    />
                  </Form.Field>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={translate('golfclub.image-url')}
                        placeholder={translate('golfclub.image-url')}
                        type="text"
                        name="imageUrl"
                        onChange={this.onChange}
                        value={this.state.imageUrl || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        action={{
                          content: translate('golfclub.upload'),
                          onClick: this.showUploadModal
                        }}
                        label={translate('golfclub.logo-url')}
                        placeholder={translate('golfclub.logo-url')}
                        type="text"
                        name="logoUrl"
                        onChange={this.onChange}
                        value={this.state.logoUrl || ''}
                      />
                    </Form.Field>
                  </Form.Group>

                  <h3>{translate('golfclub.lock-period')}</h3>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={translate('golfclub.weekday-hours')}
                        type="number"
                        name="golfCartWeekdayHours"
                        placeholder="4.5"
                        onChange={this.onChange}
                        value={this.state.golfCartWeekdayHours || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={translate('golfclub.weekend-hours')}
                        type="number"
                        name="golfCartWeekendHours"
                        placeholder="5"
                        onChange={this.onChange}
                        value={this.state.golfCartWeekendHours || ''}
                      />
                    </Form.Field>
                  </Form.Group>
                  <h3>{translate('golfclub.vat-rates')} %</h3>
                  <Form.Group widths="equal">
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.camping-vat')}`}
                        placeholder={`${translate('golfclub.camping-vat')}`}
                        type="number"
                        name="campingVat"
                        onChange={this.onChange}
                        value={this.state.campingVat?.toString() || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.lodge-vat')}`}
                        placeholder={`${translate('golfclub.lodge-vat')}`}
                        type="number"
                        name="lodgeVat"
                        onChange={this.onChange}
                        value={this.state.lodgeVat?.toString() || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.golf-cart-vat')}`}
                        placeholder={`${translate('golfclub.golf-cart-vat')}`}
                        type="number"
                        name="golfCartVat"
                        onChange={this.onChange}
                        value={this.state.golfCartVat?.toString() || ''}
                      />
                    </Form.Field>
                    <Form.Field>
                      <Form.Input
                        label={`${translate('golfclub.golf-bike-vat')}`}
                        placeholder={`${translate('golfclub.golf-bike-vat')}`}
                        type="number"
                        name="golfBikeVat"
                        onChange={this.onChange}
                        value={this.state.golfBikeVat?.toString() || ''}
                      />
                    </Form.Field>
                  </Form.Group>
                  {isSuperAdmin(this.state) && (
                    <>
                      <h3>{translate('club-admin.report-tabs.commission')} %</h3>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Input
                            label={`${translate('golfclub.camping-commission')}`}
                            placeholder={`${translate('golfclub.camping-commission')}`}
                            type="number"
                            name="campingCommission"
                            onChange={this.onChange}
                            value={this.state.campingCommission?.toString() || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label={`${translate('golfclub.lodge-commission')}`}
                            placeholder={`${translate('golfclub.lodge-commission')}`}
                            type="number"
                            name="lodgeCommission"
                            onChange={this.onChange}
                            value={this.state.lodgeCommission?.toString() || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label={`${translate('golfclub.golf-cart-commission')}`}
                            placeholder={`${translate('golfclub.golf-cart-commission')}`}
                            type="number"
                            name="golfCartCommission"
                            onChange={this.onChange}
                            value={this.state.golfCartCommission?.toString() || ''}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label={`${translate('golfclub.golf-bike-commission')}`}
                            placeholder={`${translate('golfclub.golf-bike-commission')}`}
                            type="number"
                            name="golfBikeCommission"
                            onChange={this.onChange}
                            value={this.state.golfBikeCommission?.toString() || ''}
                          />
                        </Form.Field>
                      </Form.Group>
                      <h3>{translate('golfclub.use-online-payment')}</h3>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Checkbox
                            label={translate('golfclub.use-online-payment')}
                            name="useOnlinePayment"
                            onChange={(_e, { checked }) =>
                              this.validateConnectedAccount(checked, this.state.connectedAccountId)
                            }
                            checked={this.state.useOnlinePayment}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Input
                            label={translate('golfclub.connected-account-id')}
                            placeholder={translate('golfclub.connected-account-id')}
                            type="text"
                            name="connectedAccountId"
                            onChange={(_e, { value }) =>
                              this.validateConnectedAccount(this.state.useOnlinePayment, value)
                            }
                            value={this.state.connectedAccountId || ''}
                            error={!this.state.isValidConnectedAccount}
                          />
                        </Form.Field>
                      </Form.Group>
                      <h3>
                        Widget
                        <Popup
                          trigger={<Icon name="info circle" />}
                          content={
                            <p>
                              <Translate id="golfclub.disable-widget-info" />
                            </p>
                          }
                        />
                      </h3>
                      <Form.Group widths="equal">
                        <Form.Field>
                          <Form.Checkbox
                            label={translate('golfclub.disable-widget')}
                            name="widgetDisabled"
                            onChange={this.onChangeWidgetDisabled}
                            checked={this.state.widgetDisabled}
                          />
                        </Form.Field>
                        <Form.Field>
                          <Form.Checkbox
                            label={translate('golfclub.widget-packages-only')}
                            name="widgetOfferPackagesOnly"
                            onChange={this.onChangeOfferPackagesOnly}
                            checked={this.state.widgetOfferPackagesOnly}
                          />
                        </Form.Field>
                      </Form.Group>

                      <h3>{translate('club-admin.reports')}</h3>
                      <Form.Group widths="equal">
                        <Form.Field className="d-flex">
                          <Form.Checkbox
                            label={translate('golfclub.receive-daily-bookings-report')}
                            name="receiveBookingsReport"
                            onChange={this.onChangeReceiveBookingsReport}
                            checked={this.state.receiveBookingsReport}
                          />
                          <Popup
                            trigger={<Icon name="info circle" />}
                            content={
                              <p>
                                <Translate id="golfclub.receive-daily-bookings-report-info" />
                              </p>
                            }
                          />
                        </Form.Field>
                      </Form.Group>
                    </>
                  )}
                </List.Item>

                {/* <List.Item style={{ marginTop: '30px' }}>
                <GolfCourseListComponent
                  data={this.props.data.golfCourses}
                  stateUpdated={this.subcomponentStateUpdated}
                />
                {!this.state.nameValid && <Message color="orange" visible content={translate('golfclub.enter-name')} />}
              </List.Item> */}
              </List>
              <ErrorMessage error={this.props.error} />
            </Form>
            {showUpload && (
              <Modal centered open className="modal-compact" size="tiny">
                <Modal.Header>{translate('golfclub.upload-header')}</Modal.Header>
                <Modal.Content>
                  <p>{translate('golfclub.upload-content')}</p>

                  <Form>
                    <Form.Group widths="equal">
                      <Form.Field>
                        <Form.Input
                          placeholder={translate('golfclub.image-url')}
                          type="file"
                          name="logoFile"
                          onChange={this.handleImageSelected}
                        />
                      </Form.Field>
                    </Form.Group>
                  </Form>

                  {previewImage ? (
                    <img src={previewImage} alt="preview" style={{ maxWidth: '100%', maxHeight: 250 }} />
                  ) : null}

                  {error ? <ErrorMessage error={error} /> : null}
                </Modal.Content>
                <Modal.Actions>
                  <Button color="green" onClick={this.uploadFile} disabled={!previewImage || saving} loading={saving}>
                    {translate('golfclub.upload')}
                  </Button>
                  <Button color="black" onClick={() => this.setState({ showUpload: false })}>
                    <Translate id="default.close" />
                  </Button>
                </Modal.Actions>
              </Modal>
            )}
          </>
        )}
      </Translate>
    );
  }
}

export { GolfClubViewItem, GolfClubEditItem };
