import React, { useEffect, useState } from 'react';
import { LocalizeContextProps, Translate, withLocalize } from 'react-localize-redux';
import { Button } from 'semantic-ui-react';
import { TransactionType, TransactionStatus } from '../../core/enums';
import { sumBy } from 'lodash-es';
import { formatSwedishPhoneNumber } from '../../utils';

export interface PaymentActionProps extends LocalizeContextProps {
  isCancellingBooking: any;
  isProcessingRefund: boolean;
  useOnlinePayment: any;
  golfClubCurrency: any;
  order: any;
  disableButton: any;
  loading: any;
  onProcessChargeClick: any;
  onClose: any;
  customerPhone: string;
}

function PaymentActions(props: PaymentActionProps) {
  const [refundAmount, setRefundAmount] = useState<number>(0);
  const [showStripeRefund, setShowStripeRefund] = useState<boolean>(false);
  const [showSwishRefund, setShowSwishRefund] = useState<boolean>(false);
  const [showOnTeeRefund, setShowOnTeeRefund] = useState<boolean>(false);
  const [chargeAmount, setChargeAmount] = useState<number>(0);
  const [invoiceToCancel, setInvoiceToCancel] = useState<any>(null);
  const [swishPaymentToCancel, setSwishPaymentToCancel] = useState<any>(null);
  const [refundTransaction, setRefundTransaction] = useState<any>(null);
  const [invoicePriceIncreaseCancel, setInvoicePriceIncreaseCancel] = useState<any>(null);

  useEffect(() => {
    const transactions =
      props.order?.transactions?.length > 0 ? [...props.order.transactions].sort((a, b) => b.id - a.id) : [];
    const incompleteTransaction = transactions.find(
      x => x.type === TransactionType.StripeEmailPayment && x.status === TransactionStatus.NOT_COMPLETED
    );
    const incompleteSwishTransaction = transactions.find(
      x => x.type === TransactionType.SwishPayment && x.status === TransactionStatus.NOT_COMPLETED
    );
    // TODO: reset the state each time useEffect runs, could be done by the cleanup return? search.
    setRefundAmount(0);
    setShowStripeRefund(false);
    setChargeAmount(0);
    setInvoiceToCancel(null);
    setSwishPaymentToCancel(null);
    setRefundTransaction(null);
    setInvoicePriceIncreaseCancel(null);

    if (incompleteTransaction && props.isCancellingBooking) {
      setInvoiceToCancel(incompleteTransaction);
    } else if (incompleteSwishTransaction && props.isCancellingBooking) {
      setSwishPaymentToCancel(incompleteSwishTransaction);
    } else if (incompleteTransaction && props.order.orderTotal > props.order.transactionTotal) {
      const incompleteTransactionRefunds = transactions.filter(x => x.parentTransactionId === incompleteTransaction.id);
      const refunded = sumBy(incompleteTransactionRefunds, x => x.amount);
      const pendingTransactionAmount = incompleteTransaction.amount - refunded;
      const newChargeAmount = props.order.orderTotal - pendingTransactionAmount;

      const invoiceToRefund = { transactionId: incompleteTransaction.id, amount: pendingTransactionAmount };

      setInvoicePriceIncreaseCancel(invoiceToRefund);
      setChargeAmount(newChargeAmount);
    } else {
      if (!props.isCancellingBooking && props.order.orderTotal > props.order.transactionTotal) {
        const charge = props.order.orderTotal - props.order.transactionTotal;
        setChargeAmount(charge);
      }
      if (props.isCancellingBooking || props.order.orderTotal < props.order.transactionTotal) {
        const paidTransactions = transactions.filter(
          x =>
            x.type !== TransactionType.ManualRefund &&
            x.type !== TransactionType.StripeRefund &&
            x.status !== TransactionStatus.REMOVED
        );

        // eslint-disable-next-line no-restricted-syntax
        for (const transaction of paidTransactions) {
          const transactionRefunds = transactions.filter(x => x.parentTransactionId === transaction.id);
          const refunded = Math.abs(sumBy(transactionRefunds, x => x.amount));
          const refundableAmount = transaction.amount - refunded;
          const amountToRefund = props.order.transactionTotal - props.order.orderTotal;
          const refundToGet =
            refundableAmount > amountToRefund && !props.isCancellingBooking ? amountToRefund : refundableAmount;

          if (refundToGet > 0) {
            setRefundAmount(refundToGet);
            setShowStripeRefund(
              transaction.type === TransactionType.StripeEmailPayment ||
                transaction.type === TransactionType.StripeCheckout
            );
            setShowSwishRefund(transaction.type === TransactionType.SwishPayment);
            setShowOnTeeRefund(transaction.type === TransactionType.OnTeePayment);
            setRefundTransaction({ transactionId: transaction.id, amount: refundToGet, status: transaction.status });
            break;
          }
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.order]);

  return (
    <>
      {!invoiceToCancel && !swishPaymentToCancel && (
        <>
          {!!chargeAmount && (
            <>
              {props.useOnlinePayment && (
                <p
                  dangerouslySetInnerHTML={{
                    __html: props
                      .translate('payments.payment-link', {
                        customerEmail: props.order.customerEmail
                      })
                      .toString()
                  }}
                ></p>
              )}
              <p
                dangerouslySetInnerHTML={{
                  __html: props
                    .translate('payments.manual-payment', {
                      chargeAmount: chargeAmount,
                      golfClubCurrency: props.golfClubCurrency
                    })
                    .toString()
                }}
              ></p>
              <table>
                <tbody>
                  <tr>
                    <td>{props.translate('payments.total')}</td>
                    <td style={{ textAlign: 'right' }}>
                      {props.order.orderTotal} {props.golfClubCurrency}
                    </td>
                  </tr>
                  <tr>
                    <td>{props.translate('payments.paid')}</td>
                    <td style={{ textAlign: 'right' }}>
                      {props.order.transactionTotal} {props.golfClubCurrency}
                    </td>
                  </tr>
                  <tr>
                    <td>
                      <b>{props.translate('payments.to-pay')}</b>
                    </td>
                    <td style={{ textAlign: 'right' }}>
                      <b>
                        {chargeAmount} {props.golfClubCurrency}
                      </b>
                    </td>
                  </tr>
                </tbody>
              </table>
              {invoicePriceIncreaseCancel && (
                <p>
                  Det finns en obetald faktura, du kan välja att skicka ny faktura med det uppdaterade priset eller ta
                  emot kontanter betalning
                </p>
              )}
              <h4>
                <Translate id="payments.choose-payment-type" />
              </h4>
              {props.useOnlinePayment && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.StripeEmailPayment,
                      invoicePriceIncreaseCancel
                    })
                  }
                  positive
                  disabled={props.disableButton || props.isCancellingBooking}
                  loading={props.loading}
                >
                  <Translate id="default.invoice-payment" />
                </Button>
              )}
              <Button
                onClick={() =>
                  props.onProcessChargeClick({
                    transactionType: TransactionType.ManualPayment,
                    invoicePriceIncreaseCancel
                  })
                }
                positive
                disabled={props.disableButton || props.isCancellingBooking}
                loading={props.loading}
              >
                <Translate id="default.manual-payment" />
              </Button>
              {props.customerPhone && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.SwishPayment,
                      invoicePriceIncreaseCancel,
                      swishPhoneNumber: formatSwedishPhoneNumber(props.customerPhone),
                      swishPaymentType: 'phoneNumber'
                    })
                  }
                  positive
                  disabled={props.disableButton || props.isCancellingBooking}
                  loading={props.loading}
                >
                  <Translate id="default.swish-payment" /> ({props.customerPhone})
                </Button>
              )}
              {props.onClose && (
                <Button onClick={props.onClose}>
                  <Translate id="payments.pay-later" />
                </Button>
              )}
            </>
          )}

          {!!refundAmount && (
            <>
              <p>
                <Translate
                  id="payments.refund-options"
                  data={{ refundAmount: refundAmount, currency: props.golfClubCurrency }}
                />
              </p>
              {!showStripeRefund && !showSwishRefund && !showOnTeeRefund && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.ManualRefund,
                      refundTransactionObj: { ...refundTransaction, isOnlineRefund: false }
                    })
                  }
                  positive
                  disabled={props.disableButton}
                  loading={props.loading}
                >
                  <Translate id="default.manual-refund" />
                </Button>
              )}
              {showSwishRefund && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.ManualRefund,
                      refundTransactionObj: { ...refundTransaction, isOnlineRefund: true }
                    })
                  }
                  positive
                  disabled={props.disableButton}
                  loading={props.loading}
                >
                  <Translate id="default.swish-refund" />
                </Button>
              )}
              {showOnTeeRefund && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.OnTeeRefund,
                      refundTransactionObj: { ...refundTransaction, isOnlineRefund: false }
                    })
                  }
                  positive
                  disabled={props.disableButton}
                  loading={props.loading}
                >
                  <Translate id="default.ontee-refund" />
                </Button>
              )}
              {props.useOnlinePayment && showStripeRefund && (
                <Button
                  onClick={() =>
                    props.onProcessChargeClick({
                      transactionType: TransactionType.StripeRefund,
                      refundTransactionObj: { ...refundTransaction, isOnlineRefund: true }
                    })
                  }
                  positive
                  disabled={props.disableButton}
                  loading={props.loading}
                >
                  {refundTransaction.status === TransactionStatus.NOT_COMPLETED
                    ? props.translate('payments.refund-credit')
                    : props.translate('payments.refund-to-card')}
                  {/* <Translate id="default.stripe-refund" /> */}
                </Button>
              )}
            </>
          )}
          {props.isProcessingRefund && <span>{props.translate('payments.refund-processed')}</span>}
        </>
      )}

      {!!invoiceToCancel && (
        <>
          <p>
            <Translate id="payments.cancel-invoice-information" />
            <br />* Faktura # {invoiceToCancel.id} - {invoiceToCancel.amount} {props.golfClubCurrency}
          </p>
          <Button
            onClick={() =>
              props.onProcessChargeClick({
                transactionType: TransactionType.StripeRefund,
                refundTransactionObj: { transactionId: invoiceToCancel.id, amount: invoiceToCancel.amount }
              })
            }
            positive
            disabled={props.disableButton}
            loading={props.loading}
          >
            <Translate id="default.void-invoice" />
          </Button>
        </>
      )}

      {!!swishPaymentToCancel && (
        <>
          <p>
            <Translate id="payments.cancel-swish-payment-information" />
            <br />* Payment # {swishPaymentToCancel.id} - {swishPaymentToCancel.amount} {props.golfClubCurrency}
          </p>
          <Button
            onClick={() =>
              props.onProcessChargeClick({
                transactionType: TransactionType.StripeRefund,
                refundTransactionObj: { transactionId: swishPaymentToCancel.id, amount: swishPaymentToCancel.amount }
              })
            }
            positive
            disabled={props.disableButton}
            loading={props.loading}
          >
            <Translate id="default.cancel-swish-payment" />
          </Button>
        </>
      )}
    </>
  );
}

export default withLocalize(PaymentActions);
