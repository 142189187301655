import { DefaultVat, RentalUnitType } from '../core/constants';
import { DiscountTypes, PriceType } from '../core/enums';
import { calculateAddonPrice, calculatePackagePrice } from '../core/services/product.service';
import { getDateRangeListExcludingEndDate } from './date-time.util';
import { getAvailableAccommodation } from './booking.util';

export const calculatePrice = (accommodation, dateFrom, dateTo, pricings, priceType) => {
  if (!accommodation || !accommodation.priceCategory || !pricings) {
    return 0;
  }
  const pricingId = accommodation.priceCategory.id;
  const dates = getDateRangeListExcludingEndDate(dateFrom, dateTo);
  let totalPrice = 0;
  dates.forEach(date => {
    const price = findPricing(pricingId, date, pricings);

    const priceToUse =
      priceType === PriceType.RACK && price.rackPriceInclVat > 0 ? price.rackPriceInclVat : price.sellPriceInclVat;

    totalPrice += priceToUse;
  });
  return totalPrice;
};

const findPricing = (pricingId, date, pricings) => {
  const priceInfo = pricings.find(x => x.dates.map(y => y.substring(0, 10)).indexOf(date) >= 0);
  if (!priceInfo) {
    return { sellPriceInclVat: 0 };
  }
  const pricing = priceInfo.pricings.find(x => x.priceCategory.id === pricingId);
  return pricing;
};

export const calculateDiscountedPrice = (originalPrice, discount) => {
  let discountedPrice = originalPrice;
  if (discount.discountType === DiscountTypes.PERCENT) {
    discountedPrice -= discountedPrice * (discount.amount / 100);
  } else if (discount.discountType === DiscountTypes.FIXED_AMOUNT) {
    discountedPrice -= discount.amount;
  }

  return discountedPrice;
};

export const getOriginalPrice = (booking, accommodationAvailability, isHoliday) => {
  if (booking.packages && booking.packages.length > 0) {
    let packagesPrice = 0;
    let paidNightsCount = 0;

    // calculate price and sum up paid nights for each package
    booking.packages.forEach(p => {
      packagesPrice += calculatePackagePrice(p, booking.checkInDate, isHoliday);
      paidNightsCount += p.count;
    });

    const missingNights = (booking.numberOfGuests || 1) - paidNightsCount;
    if (missingNights > 0) {
      // calculate missing night price from common accommodation price
      const { pricing } = getAvailableAccommodation({
        accommodationId: booking.accommodation.id,
        defaultAvailability: accommodationAvailability,
        priceType: booking.priceType,
        numberOfGuests: missingNights
      });

      if (pricing) {
        packagesPrice += pricing.price;
      }
    }

    return packagesPrice;
  }

  // TODO Check if it does not break something
  // return booking.originalPrice > 0 ? booking.originalPrice : booking.priceInclVat;
  const { pricing } = getAvailableAccommodation({
    accommodationId: booking.accommodation.id,
    defaultAvailability: accommodationAvailability,
    priceType: booking.priceType,
    numberOfGuests: booking.numberOfGuests
  });

  return pricing?.price || (booking.originalPrice > 0 ? booking.originalPrice : booking.priceInclVat);
};

export const getDiscountedPrice = (originalPrice, discounts) => {
  if (discounts?.length > 0) {
    const discount = discounts[0].discount || discounts[0];
    const discountedPrice = discount ? calculateDiscountedPrice(originalPrice, discount) : originalPrice;
    return { discount, discountedPrice };
  }

  return { discount: null, discountedPrice: null };
};

export const calculateBookingPrice = (booking, accommodationAvailability, isHoliday) => {
  const originalPrice = getOriginalPrice(booking, accommodationAvailability, isHoliday);
  const { discountedPrice } = getDiscountedPrice(originalPrice, booking.accommodationBookingDiscounts);

  let addonsPrice = 0;

  if (booking.productAddons) {
    booking.productAddons.forEach(addon => {
      addonsPrice += calculateAddonPrice(addon, true);
    });
  }

  if (booking.accommodationBookingDiscounts && booking.accommodationBookingDiscounts.length > 0) {
    // could be zero price when 100% discount applied
    return discountedPrice + addonsPrice;
  }

  return originalPrice + addonsPrice;
};

/**
 * Validates existing booking discounts against possible rental unit types
 * that the discounts can be applied to. Returns valid discounts for a rental unit
 * type id.
 * @param {*} rentalUnitTypeId Rental unit type for discounts validation.
 * @param {*} booking Booking which discounts should be validated.
 * @returns An array of valid discounts for the booking.
 */
export const getValidDiscounts = (rentalUnitTypeId, booking) => {
  const discountValidForRentalUnitTypeIds = booking.accommodationBookingDiscounts[0]?.discount?.rentalUnitTypeIds;
  const validAccommodationBookingDiscounts =
    discountValidForRentalUnitTypeIds?.length === 0 || discountValidForRentalUnitTypeIds?.includes(rentalUnitTypeId)
      ? booking.accommodationBookingDiscounts
      : [];
  return validAccommodationBookingDiscounts;
};

/**
 * Validates existing booking selected package against possible rental unit type
 * that the package can be applied to.
 * @param {*} rentalUnitTypeId Rental unit type for selected package validation.
 * @param {*} booking Booking which selected package should be validated.
 * @returns Selected package if valid or null if not valid.
 */
export const getValidPackage = (rentalUnitTypeId, booking) => {
  if (!booking.selectedPackage) {
    return null;
  }

  return booking.selectedPackage.rentalUnitTypes.includes(rentalUnitTypeId) ? booking.selectedPackage : null;
};

export const getPriceExclVat = (priceInclVat, typeId, golfClub) => {
  const golfClubVat = getVatPercentage(typeId, golfClub);

  return priceInclVat / (golfClubVat / 100 + 1);
};

export const getVatPercentage = (typeId, golfClub) => {
  switch (typeId) {
    case RentalUnitType.CAMPING.id:
      return golfClub.campingVat;
    case RentalUnitType.LODGE.id:
      return golfClub.lodgeVat;
    case RentalUnitType.GOLF_CART.id:
      return golfClub.golfCartVat;
    case RentalUnitType.GOLF_BIKE.id:
      return golfClub.golfBikeVat;
    default:
      return DefaultVat;
  }
};

/** Return a formatted price (two decimals) with a currency */
export const getFormattedPrice = (price, currency) =>
  `${Number(price).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 })} ${currency}`;

/** Return a formatted number (two decimals) with a currency */
export const getFormattedNumber = number =>
  Number(number).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2 });

export const priceCategories = {
  1: 'CampingWithElectricity',
  2: 'Lodge4Beds',
  5: 'CampingNoElectricity',
  6: 'Lodge6Beds',
  8: 'Rooms'
};
